import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SessaoService } from "./sessao.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
    private sessao: SessaoService
  ) {}

  requisicao(url, param) {
    console.log(url);
    console.log(param);
    this.loader.start();

    this.sessao.setaultimareq();

    return this.http
      .post(url, param)
      .toPromise()
      .then((result: any) => {
        // console.log(result);
        this.loader.stop();
        return result;
      })
      .catch((erro) => {
        this.loader.stop();
        console.log(erro);
      })
      .finally(() => this.loader.stop());
  }

  requisicaoFile(url, param) {
    // console.log(param)
    this.loader.start();

    this.sessao.setaultimareq();

    return this.http
      .post(url, param)
      .toPromise()
      .then((result: any) => {
        // console.log(result)
        this.loader.stop();
        return result;
      })
      .catch((erro) => {
        this.loader.stop();
        console.log(erro);
      })
      .finally(() => this.loader.stop());
  }

  requisicaoGetFile(url, param) {
    // console.log(param);
    this.loader.start();

    this.sessao.setaultimareq();

    return this.http
      .post(url, param, { responseType: "blob" })
      .toPromise()
      .then((result: any) => {
        // console.log(result);
        this.loader.stop();
        return result;
      })
      .catch((erro) => {
        this.loader.stop();
        console.log(erro);
      })
      .finally(() => this.loader.stop());
  }
}
