import { stringify } from 'querystring'

export class Utils {
  static date(data:Date){
    let dia=Utils.zeroaesquerda(data.getDate())
    let mes=Utils.zeroaesquerda(data.getMonth()+1)
    let ano=data.getFullYear()

    return dia+"/"+mes+"/"+ano
  }

  static jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
  }

  static currencyToString(valor){
    let value=valor.toString();
    value=value.split('.')
    let retorno=''
    if(value.length>1){
      retorno=value[0]+',';
      if(value[1].length<3){
        retorno+=value[1]
        for(let cont; cont<=2-value[1].length;cont++){
          retorno+='0';
        }
      }else{
        retorno+='00';
      }
    }else{
      retorno=value[0]+',00';
    }
    return retorno;
  }

  static imgToDate(img:string){

    if(img.length<8)
      return false;
      
    if(img=="22_10_2019__08_03____vzmylzbykk.jpeg" || img== "22_10_2019__14_02____pshuuffnuw.jpeg")
      return true;
    
    let datestr=img.split('_');
    let date=new Date();

    date.setDate(parseInt(datestr[0]))
    date.setMonth(parseInt(datestr[1])-1)
    date.setFullYear(parseInt(datestr[2]))

    let limit=new Date();

    limit.setDate(21)
    limit.setMonth(9)
    limit.setFullYear(2019)

    
    
      return date<limit;
  }

  static strToday(){
    return this.date(new Date())
  }

  static strDate(dt:string){
    if(dt!=undefined){
      let date= new Date()
      let datatemp=dt.split('-')

      date.setFullYear(parseInt(datatemp[0]))
      date.setMonth(parseInt(datatemp[1])-1)
      date.setDate(parseInt(datatemp[2]))


      return this.date(date)
    }else{
      return "indefinido"
    }

  }

  static zeroaesquerda(numero){
    let num=parseInt(numero)
    if(num>9){
      return num.toString()
    }else{
      return "0"+num.toString();
    }

  }

  static currency(numero) {
    var num = numero.toFixed(2).split('.');
    num[0] = "" + num[0].split(/(?=(?:...)*$)/).join('.');
    return num.join(',');
  }

  
}
