import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { SessaoService } from '../services/sessao.service';
import { Paths } from '../services/paths';

declare var $ : any;

declare const jQuery: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Output() logou = new EventEmitter();
  login={usuario:"",senha:""}

  constructor(private http:HttpService, private modal:ModalService, private sessao: SessaoService){

  }

  ngOnInit(){

  }

  logar(){
    this.http.requisicao(Paths.logar, this.login).then((result)=>{
      if (result.status==200){
        this.sessao.logado=1;
        this.logou.emit(1)
        localStorage.setItem('login','1')
      }else{
        this.modal.show(400,'Usuario ou senha invalidos')
      }
    })

  }




}
