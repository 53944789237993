import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { SessaoService } from 'src/app/services/sessao.service';
import { Paths } from '../services/paths';

declare var $ : any;

declare const jQuery: any;

@Component({
  selector: 'app-rankg',
  templateUrl: './rankg.component.html',
  styleUrls: ['./rankg.component.scss']
})
export class RankGComponent implements OnInit {

  logado=0;

  ranks:any=[]
  rank={descricao:"", obs:""};


  tabela;

  constructor(private sessao: SessaoService,private http:HttpService, private modal:ModalService, private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.buscar();
    this.logado=this.sessao.logado;
  }

  buscar(){
    if(this.tabela!=undefined){
      this.tabela.clear()
      this.tabela.destroy()
    }
    // console.log(URL+'/jogador/add/');
    this.http.requisicao(Paths.rankget,'').then((result)=>{
      console.log(result);
      this.ranks=result;


      this.chRef.detectChanges()
      this.tabela=$("#tabela").DataTable({
        "autoWidth": false,
          destroy: true,
          responsive:true,
          searching:false,
          pageLength: 30,
          lengthChange : false,
          "paging":   false,
          "ordering": false,
          "info":     false
      });
    })


  }

  modalCadastro(){
    $('#ModalJogador').modal('show');
  }

  cadastrar(){
    // console.log(URL+'/jogador/add/');
    this.http.requisicao(Paths.rankadd, this.rank).then((result)=>{
      $('#ModalJogador').modal('hide');
      this.rank.descricao='';
      this.rank.obs='';
      this.modal.show(200,"Cadastrado com sucesso")
      this.buscar();
    })
  }


}
