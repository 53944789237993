import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgxUiLoaderModule } from 'ngx-ui-loader';


import { AppComponent } from './app.component';
import { TopbarComponent } from './layout/topbar/topbar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { DemoNumber } from './services/NumberFor.pipe';
import { CurrencyPipe } from './services/currency.pipe';
import { JogadorComponent } from './jogador/jogador.component';
import { JogoComponent } from './jogo/jogo.component';
import { TabelaComponent } from './jogo/tabela/tabela.component';
import { LoginComponent } from './login/login.component';
import { RankGComponent } from './rank/rankg.component';
import { RankingComponent } from './ranking/ranking.component';
import { FinanceiroComponent } from './financeiro/financeiro.component';

import { FileSelectDirective } from 'ng2-file-upload';

import { ImageCropperModule } from 'ngx-image-cropper';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { PositivePipe } from './services/positive.pipe';
import { EstatisticasComponent } from './estatisticas/estatisticas.component';
import { MaxDecimalPipe } from './services/maxdecimal.pipe';






@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    SidebarComponent,
    FooterComponent,


    JogadorComponent,
    JogoComponent,
    TabelaComponent,
    LoginComponent,
    RankGComponent,
    RankingComponent,
    FinanceiroComponent,
    EstatisticasComponent,
    MaxDecimalPipe,



    DemoNumber,
    CurrencyPipe,
    PositivePipe,

    FileSelectDirective
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule, HttpClientModule,
    NgxUiLoaderModule,
    ImageCropperModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
