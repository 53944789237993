import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class SessaoService {

  constructor(private http: HttpClient, private loader: NgxUiLoaderService) { }

  tempoinatividade = 360;



  logado = 0;


  setaultimareq() {
    localStorage.setItem('ultimareq', JSON.stringify(new Date()));
    // o injeta sessão chama este metodo quando é chamado
  }

  conferevalidade() {
    if (localStorage.getItem('login') !== undefined) {
      if (localStorage.getItem('login') === '1') {
          const ultimareq = new Date(JSON.parse(localStorage.getItem('ultimareq')));
          const agora = new Date();
          console.log('agora', agora);
          console.log('ultimareq', ultimareq);

          const diff = Math.abs(agora.getTime() - ultimareq.getTime());

          const minutos = diff / ( 1000 * 60);
          console.log(minutos);
          if (minutos > this.tempoinatividade) {

            this.logado = 0;
          } else {
            this.logado = 1;
          }
        }
    }
  }
}
