import { ImageService } from './../services/image.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { Utils } from 'src/app/services/utils';
import { SessaoService } from 'src/app/services/sessao.service';

import {
  FileUploader,
  FileSelectDirective,
} from 'ng2-file-upload/ng2-file-upload';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Paths } from '../services/paths';

declare var $: any;
declare const jQuery: any;

@Component({
  selector: 'app-jogo',
  templateUrl: './jogo.component.html',
  styleUrls: ['./jogo.component.scss'],
})
export class JogoComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({
    url: Paths.imgadd,
    itemAlias: 'foto',
  });

  logado = 0;

  editando = false;

  jogos: any = [];
  jogo = {
    id: 0,
    rank: 0,
    data: '',
    local: '',
    numero: 0,
    obs: '',
    jogadoresJogo: [],
    img: '',
    horainicio: '19:00',
  };
  numJogador = 3;

  jogadoresJogo: any[] = [
    {
      jogador: 0,
      eliminou: 0,
      colocacao: 1,
      rebuy: 0,
      hora: '19:00',
      horaentrada: '19:00',
      primeiraeliminacao: true,
    },
    {
      jogador: 0,
      eliminou: 0,
      colocacao: 2,
      rebuy: 0,
      hora: '19:00',
      horaentrada: '19:00',
      primeiraeliminacao: true,
    },
    {
      jogador: 0,
      eliminou: 0,
      colocacao: 3,
      rebuy: 0,
      hora: '19:00',
      horaentrada: '19:00',
      primeiraeliminacao: true,
    },
  ];

  numjogadordisplay: any[] = ['1', '2', '3'];

  lastNum = 0;
  rankselecionado = 0;

  ranks = [];
  jogadores = [];

  modalImagem = 0;

  fileData: File = null;
  imgurl = '';
  imageChangedEvent: any;
  imageBase64: any;
  imgcropped: any;

  tabela;

  isDesktopDevice = this.deviceService.isDesktop();

  // tslint:disable-next-line:max-line-length
  constructor(
    private deviceService: DeviceDetectorService,
    private sessao: SessaoService,
    private imgService: ImageService,
    private http: HttpService,
    private modal: ModalService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.buscar();

    $('#datajogo').datepicker({
      format: 'dd/mm/yyyy',
      todayHighlight: true,
    });

    $('#modalImagem').on('hidden.bs.modal', () => {
      $('#ModalJogador').modal('show');
    });

    this.logado = this.sessao.logado;

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
  }

  fileChangeEvent(event: any): void {
    this.imageBase64 = undefined;
    this.imageChangedEvent = event;
    this.uploader.clearQueue();
    this.uploader.cancelAll();
    $('#ModalJogador').on('hidden.bs.modal', function(e) {
      $('#modalCropped').modal('show');
      // clear this function so it doesn't show up if they exit the window again
      $('#ModalJogador').off();
    });
    $('#ModalJogador').modal('hide');
  }

  confirmaCropped() {
    console.log(this.imgcropped);
    this.uploader.addToQueue([this.imgcropped], { autoUpload: true });

    console.log('aqui');

    if (this.imageBase64 != undefined && this.imageChangedEvent == undefined) {
      if (this.uploader.getNotUploadedItems().length > 0) {
        this.uploader.uploadAll();
        this.uploader.onCompleteItem = (
          item: any,
          response: any,
          status: any,
          headers: any
        ) => {
          console.log('ImageUpload:uploaded:', item, status, response);
          // alert('File uploaded successfully');
          console.log(response);
          const resposta = JSON.parse(response);
          this.http
            .requisicao(Paths.imgupdate, {
              id: this.jogo.id,
              img: resposta.img,
            })
            .then((result) => {
              $('#ModalJogador').modal('hide');
              $('#modalCropped').modal('hide');
              this.modal.show(200, 'Atualizado com sucesso');
              this.imgcropped = undefined;
              this.imageBase64 = undefined;
              this.uploader.clearQueue();
              this.uploader.cancelAll();
              this.buscar();
            });
        };
      }
    } else {
      $('#modalCropped').on('hidden.bs.modal', function(e) {
        $('#ModalJogador').modal('show');
        // clear this function so it doesn't show up if they exit the window again
        $('#modalCropped').off();
      });
      $('#modalCropped').modal('hide');
    }
  }

  imageCropped(event) {
    this.imgcropped = event.file;
  }

  aumentaJogador() {
    // if (this.jogadoresJogo.length < 20) {
    this.numjogadordisplay.push(this.pegaultimonumero());
    this.numJogador++;
    this.jogadoresJogo.push({
      jogador: 0,
      eliminou: 0,
      colocacao: 0,
      rebuy: 0,
      hora: '19:00',
      horaentrada: '19:00',
      primeiraeliminacao: true,
    });
    this.mask();
    // }
  }

  pegaultimonumero() {
    for (let cont = this.numjogadordisplay.length - 1; cont >= 0; cont--) {
      if (this.numjogadordisplay[cont] !== '-') {
        // tslint:disable-next-line:radix
        const num = parseInt(this.numjogadordisplay[cont]) + 1;
        return num.toString();
      }
    }
  }

  mask() {
    this.chRef.detectChanges();
    $('.horaEliminacao').inputmask({ mask: '99:99' });
    $('.horaEliminacao').click(function() {
      $(this).select();
    });

    $('#horajogo').inputmask({ mask: '99:99' });
    $('#horajogo').click(function() {
      $(this).select();
    });
  }

  setaHoraEliminacao() {
    for (let cont = 0; cont < this.numJogador; cont++) {
      $('#horaEliminacao' + cont.toString()).val(this.jogadoresJogo[cont].hora);
      $('#horaEntrada' + cont.toString()).val(
        this.jogadoresJogo[cont].horaentrada
      );
    }

    $('#horajogo').val(this.jogo.horainicio);
  }

  pegaHoraEliminacao() {
    for (let cont = 0; cont < this.numJogador; cont++) {
      this.jogadoresJogo[cont].hora = $(
        '#horaEliminacao' + cont.toString()
      ).val();
      this.jogadoresJogo[cont].horaentrada = $(
        '#horaEntrada' + cont.toString()
      ).val();
    }

    this.jogo.horainicio = $('#horajogo').val();
  }

  diminuiJogador() {
    if (this.jogadoresJogo.length > 3) {
      this.numJogador--;
      this.jogadoresJogo.pop();
      this.numjogadordisplay.pop();
      this.mask();
    }
  }

  blurtimechangeJogador() {
    console.log('chego');
    this.pegaHoraEliminacao();
    console.log(this.jogadoresJogo);
    this.changeJogador();
  }

  changeJogador() {
    // this.pegaHoraEliminacao();
    let num = 1;
    const jogstemp = [];

    const jogstempreverse = [];

    for (let cont = 0; cont < this.numJogador; cont++) {
      let existe = false;
      for (const c in jogstemp) {
        if (this.jogadoresJogo[cont].jogador !== 0) {
          if (
            this.jogadoresJogo[cont].jogador.toString() ===
            jogstemp[c].toString()
          ) {
            existe = true;
          }
        }
      }
      jogstemp.push(this.jogadoresJogo[cont].jogador);
      if (existe) {
        this.numjogadordisplay[cont] = '-';
        this.jogadoresJogo[cont].colocacao = 0;
      } else {
        this.numjogadordisplay[cont] = num.toString();
        this.jogadoresJogo[cont].colocacao = num;
        num++;
      }
    }

    for (let cont = this.numJogador - 1; cont >= 0; cont--) {
      let existe = false;
      let pos = 0;
      for (const c in jogstempreverse) {
        if (this.jogadoresJogo[cont].jogador !== 0) {
          if (
            this.jogadoresJogo[cont].jogador.toString() ===
            jogstempreverse[c].jogador.toString()
          ) {
            existe = true;
            pos = parseInt(c);
          }
        }
      }

      if (existe) {
        this.jogadoresJogo[cont].primeiraeliminacao = false;
        this.jogadoresJogo[cont].horaentrada = jogstempreverse[pos].hora;
        jogstempreverse[pos] = this.jogadoresJogo[cont];
      } else {
        this.jogadoresJogo[cont].primeiraeliminacao = true;
        jogstempreverse.push(this.jogadoresJogo[cont]);
      }
    }

    this.mask();
    this.setaHoraEliminacao();
  }

  buscar() {
    console.log(URLHTTP + '/jogador/get/');
    this.http.requisicao(Paths.jogoget, '').then((result) => {
      if (this.tabela !== undefined) {
        this.tabela.clear();
        this.tabela.destroy();
      }
      this.jogos = result.jogos;
      this.jogadores = result.jogadores;
      this.ranks = result.ranks;
      this.lastNum = result.numero;
      this.rankselecionado = result.rankselecionado;

      // tslint:disable-next-line:forin
      for (const cont in this.jogos) {
        this.jogos[cont].data = Utils.strDate(this.jogos[cont].data);
        this.jogos[cont].imgerrada = Utils.imgToDate(this.jogos[cont].img);
      }
      this.chRef.detectChanges();
      console.log(this.jogos);
      this.tabela = $('#tabelaJogos').DataTable({
        autoWidth: false,
        destroy: false,
        responsive: true,
        searching: false,
        pageLength: 10,
        lengthChange: false,
        paging: true,
        ordering: false,
        info: false,
        columnDefs: [
          { responsivePriority: 0, targets: 0 },
          { responsivePriority: 4, targets: 1 },
          { responsivePriority: 2, targets: 2 },
          { responsivePriority: 5, targets: 3 },
          { responsivePriority: 6, targets: 4 },
          { responsivePriority: 1, targets: 5 },
          // { responsivePriority: 3 , targets: 6 },
        ],
      });
      // this.tabela.draw()
    });
  }

  modalCadastro() {
    this.editando = false;

    this.imgcropped = undefined;
    this.uploader.clearQueue();
    this.uploader.cancelAll();

    this.numJogador = 3;
    this.numjogadordisplay = ['1', '2', '3'];

    this.jogadoresJogo = [
      {
        jogador: 0,
        eliminou: 0,
        colocacao: 1,
        rebuy: 0,
        hora: '19:00',
        horaentrada: '19:00',
        primeiraeliminacao: true,
      },
      {
        jogador: 0,
        eliminou: 0,
        colocacao: 2,
        rebuy: 0,
        hora: '19:00',
        horaentrada: '19:00',
        primeiraeliminacao: true,
      },
      {
        jogador: 0,
        eliminou: 0,
        colocacao: 3,
        rebuy: 0,
        hora: '19:00',
        horaentrada: '19:00',
        primeiraeliminacao: true,
      },
    ];

    this.jogo = {
      id: 0,
      horainicio: '19:00',
      img: '',
      rank: this.rankselecionado,
      data: '',
      local: '',
      numero: undefined,
      obs: '',
      jogadoresJogo: [],
    };
    this.jogo.numero = this.lastNum;

    this.setaHoraEliminacao();

    $('#selectrank').val(this.rankselecionado);
    $('#selectrank').attr('disabled', false);
    $('#selectrank').selectpicker('refresh');

    this.jogo.data = Utils.strToday();
    $('#datajogo').datepicker('setDate', this.jogo.data);

    this.mask();

    $('#ModalJogador').modal('show');
  }

  getParams() {
    this.jogo.data = $('#datajogo').val();
    this.pegaHoraEliminacao();
    this.jogo.jogadoresJogo = this.jogadoresJogo;

    if (this.jogo.hasOwnProperty('jogadores')) { delete this.jogo["jogadores"]; }

    this.jogo.data = $('#datajogo').val();

    let msg = '';
    this.jogadoresJogo.forEach((element, index) => {
      if (!(element.jogador > 0)) {
        msg +=
          '<div class=\'erroitem\'>Jogador da linha ' +
          (index + 1).toString() +
          ' invalido </div>';
      }
    });

    if (!(this.jogo.rank > 0)) {
      msg += "<div class='erroitem'>Selecione um rank valido  </div>";
    }

    if (this.jogo.data.length != 10) {
      msg += "<div class='erroitem'>Selecione uma data valida  </div>";
    }

    if (!(this.jogo.numero > 0)) {
      msg += "<div class='erroitem'>O numero deve ser maior que 0  </div>";
    }

    if (this.jogo.local.length < 4) {
      msg +=
        "<div class='erroitem'>O local deve ter mais de 4 caracteres  </div>";
    }

    if (msg != '') {
      this.modal.show(400, msg);
      return false;
    } else {
      return true;
    }
  }

  update() {
    if (!this.getParams()) { return false; }

    if (this.uploader.getNotUploadedItems().length > 0) {
      this.uploader.uploadAll();
      this.uploader.onCompleteItem = (
        item: any,
        response: any,
        status: any,
        headers: any
      ) => {
        console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
        console.log(response);
        const resposta = JSON.parse(response);
        console.log(resposta);
        this.atualizar(resposta.img);
      };
    } else {
      this.atualizar();
    }
  }

  atualizar(img = '') {
    if (img === '' || img === undefined) {
      // this.jogo.img = '';
      delete this.jogo['img'];
    } else {
      this.jogo.img = img;
    }

    console.log('atual');
    console.log(this.jogo);

    this.http.requisicao(Paths.jogoatualizar, this.jogo).then((result) => {
      this.afterInsertUpdate('Atualizado com sucesso');
    });
  }

  cadastrar() {
    if (!this.getParams()) { return false; }

    if (this.uploader.getNotUploadedItems().length > 0) {
      this.uploader.uploadAll();
      this.uploader.onCompleteItem = (
        item: any,
        response: any,
        status: any,
        headers: any
      ) => {
        console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
        console.log(response);
        const resposta = JSON.parse(response);
        console.log(resposta);
        this.inserirJogo(resposta.img);
      };
    } else {
      this.inserirJogo();
    }
  }

  inserirJogo(imgsrc = '') {
    if (imgsrc === '' || imgsrc === undefined) {
      this.jogo.img = '';
    } else {
      this.jogo.img = imgsrc;
    }

    this.http.requisicao(Paths.jogoadd, this.jogo).then((result) => {
      this.afterInsertUpdate('Cadastrado com sucesso');
    });
  }

  afterInsertUpdate(msg) {
    $('#ModalJogador').modal('hide');
    this.modal.show(200, msg);
    this.imgcropped = undefined;
    this.uploader.clearQueue();
    this.uploader.cancelAll();
    this.buscar();
  }

  detalhar(id) {
    this.editando = false;
    this.jogo = {
      id: 0,
      horainicio: '19:00',
      rank: 0,
      data: '',
      local: '',
      numero: 0,
      obs: '',
      jogadoresJogo: [],
      img: '',
    };
    this.jogos.forEach((element) => {
      if (element.id === id) {
        this.jogo = element;
        $('#selectrank').val(element.idranking);
        $('#selectrank').attr('disabled', true);
        $('#selectrank').selectpicker('refresh');

        this.jogadoresJogo = [];
        // tslint:disable-next-line:forin
        for (const cont in element.jogadores) {
          const idj = element.jogadores[cont].idjogador;
          const eli = element.jogadores[cont].eliminadopor;
          const reb = element.jogadores[cont].rebuy;
          const hr = element.jogadores[cont].hora;
          const hrentr = element.jogadores[cont].horaentrada;
          const primeiraelim = element.jogadores[cont].primeiraeliminacao;
          this.jogadoresJogo.push({
            jogador: idj,
            eliminou: eli,
            rebuy: reb,
            hora: hr,
            horaentrada: hrentr,
            primeiraeliminacao: primeiraelim,
          });
        }
        this.numJogador = this.jogadoresJogo.length;
      }
    });

    this.changeJogador();

    this.mask();
    this.setaHoraEliminacao();

    $('#ModalJogador').modal('show');
  }

  editar(id) {
    this.imgcropped = undefined;
    this.uploader.clearQueue();
    this.uploader.cancelAll();

    this.editando = true;

    this.http.requisicao(Paths.jogogetedit, { id }).then((response) => {
      this.jogo = {
        id: 0,
        horainicio: '19:00',
        rank: response.rank,
        data: '',
        local: '',
        numero: 0,
        obs: '',
        jogadoresJogo: [],
        img: '',
      };
      this.jogadoresJogo = [];
      const jogotemp = response;
      jogotemp.rank = jogotemp.idranking;
      delete jogotemp['idranking'];

      this.jogo = jogotemp;
      this.jogo.data = Utils.strDate(this.jogo.data);

      $('#datajogo').datepicker('setDate', this.jogo.data);
      $('#selectrank').val(response.rank);
      $('#selectrank').attr('disabled', false);
      $('#selectrank').selectpicker('refresh');

      this.numJogador = response.jogadores.length;
      // tslint:disable-next-line:forin
      for (const cont in response.jogadores) {
        // console.log(response.jogadores[cont]);
        this.jogadoresJogo.push({
          jogador: response.jogadores[cont].idjogador,
          eliminou: response.jogadores[cont].eliminadopor,
          hora: response.jogadores[cont].hora,
          horaentrada: response.jogadores[cont].horaentrada,
          primeiraeliminacao: response.jogadores[cont].primeiraeliminacao,
        });
      }

      this.changeJogador();

      this.mask();

      this.setaHoraEliminacao();

      $('#ModalJogador').modal('show');
    });
  }

  inativar(id) {
    this.http.requisicao(Paths.jogoinativar, { id }).then((result) => {
      $('#ModalJogador').modal('hide');
      this.modal.show(200, 'Inativado com sucesso');
      this.buscar();
    });
  }

  mostrar() {
    console.log(this.jogadoresJogo);
  }

  mostraImagem(id) {
    const req = { img: this.jogo.img };
    this.http.requisicaoGetFile(Paths.imgget, req).then((response) => {
      const reader = this.imgService.createImageFromBlob(response);
      reader.addEventListener('load', () => {
        this.imgurl = this.imgService.imageToShow;
        $('#ModalJogador').on('hidden.bs.modal', function(e) {
          $('#modalImagem').modal('show');
          // clear this function so it doesn't show up if they exit the window again
          $('#ModalJogador').off();
        });

        $('#ModalJogador').modal('hide');

        //  $('#modalImagem').modal('show');
      });
    });
  }

  editaImgErr(id) {
    let imglink;
    this.jogos.forEach((element) => {
      if (element.id == id) {
        imglink = element.img;
        this.jogo = element;
      }
    });

    const req = { img: imglink };
    this.http.requisicaoGetFile(Paths.imgget, req).then((response) => {
      const reader = this.imgService.createImageFromBlob(response);
      reader.addEventListener('load', () => {
        //  this.imgurl = this.imgService.imageToShow;

        //  $('#ModalJogador').modal('hide');

        //  $('#modalImagem').modal('show');

        this.imageChangedEvent = undefined;
        this.imageBase64 = this.imgService.imageToShow;
        this.uploader.clearQueue();
        this.uploader.cancelAll();
        $('#ModalJogador').on('hidden.bs.modal', function(e) {
          $('#modalCropped').modal('show');
          // clear this function so it doesn't show up if they exit the window again
          $('#ModalJogador').off();
        });
        $('#ModalJogador').modal('hide');
      });
    });
  }
}
