import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SessaoService } from './sessao.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

    imageToShow: any;

  constructor(private http: HttpClient, private loader: NgxUiLoaderService, private sessao: SessaoService) { }



    createImageFromBlob(image: Blob) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.imageToShow = reader.result;
            }, false);

            if (image) {
                const t = reader.readAsDataURL(image);
            }
            return reader;
    }
}
