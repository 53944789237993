import { Component, OnInit, Input } from "@angular/core";
import * as $ from "jquery";
import { ModalService } from "./services/modal.service";
import { SessaoService } from "./services/sessao.service";

declare const jQuery: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  page = 2;
  // page = 5;
  logado;

  modal = { msg: "", status: 403 };
  _subscription: any;

  constructor(private modalS: ModalService, private sessao: SessaoService) {
    this._subscription = modalS.modalChange.subscribe((value) => {
      this.modal = value;
    });

    this.sessao.conferevalidade();
  }

  ngOnInit() {
    (function ($) {
      const tabs = $(".tabs li a");

      tabs.click(function () {
        tabs.removeClass("active");
        $(this).addClass("active");
      });
    })(jQuery);
  }

  onPageChanged(event) {
    console.log("valor");

    if ($(".sidebar").hasClass("toggled")) {
      // $('.sidebar .collapse').collapse('hide');
    } else {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
    }
    this.page = event;
    console.log(event);
  }

  login(num) {
    this.logado = num;
    this.page = 2;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this._subscription.unsubscribe();
  }
}
