import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'maxdecimalpipe'})
export class MaxDecimalPipe implements PipeTransform {
  transform(value,size=-1, args:string[]) : any {
	let valor=value.toString();


	if(size>=0){

		let num=[]

		if(valor.indexOf(".")>=0){
			num=valor.split('.')
		


		}else{
			num=[valor,""];
		}


		if(num[1].length>=size){
			
			return num[0]+','+num[1].substring(0,size)

		}else{
			let tamanho=num[1].length
			while(tamanho<size){
				num[1]=num[1]+"0";
				tamanho=num[1].length
			}

			return num[0]+','+num[1]

		}

	
	
	
	
	}else{
		return valor.replace('.',',')
	}
	

	
  }
}
