import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { SessaoService } from 'src/app/services/sessao.service';
import { Paths } from '../services/paths';

declare var $ : any;

declare const jQuery: any;

@Component({
  selector: 'app-estatisticas',
  templateUrl: './estatisticas.component.html',
  styleUrls: ['./estatisticas.component.scss']
})
export class EstatisticasComponent implements OnInit {

  logado=0;


  estatisticas:any;

  ranks=[]


  tabela;

  mdstyle={
    color:"primary",
    title:"HEADS-UP LEADER",
    legenda:""
  }
  detailtable=[];

  rankselecionado

  constructor(private sessao: SessaoService,private http:HttpService, private modal:ModalService, private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.buscar();
    this.logado=this.sessao.logado;
  }

  buscar(){
    if(this.tabela!=undefined){
      this.tabela.clear()
      this.tabela.destroy()
    }
    // console.log(URL+'/jogador/add/');
    this.http.requisicao(Paths.estatisticas,'').then((result)=>{
      
      this.estatisticas=result;

      this.ranks=result.ranks;

      delete this.estatisticas['ranks'];

      this.rankselecionado=this.ranks[0].id;
      

    })
  }
  mudarank(){
    this.http.requisicao(Paths.estatisticasbusca,{idrank:this.rankselecionado}).then((result)=>{
      this.estatisticas=result
    })
  }

  detalhar(infos,style){
    this.detailtable=infos;
    this.mdstyle=style;
    $('#modalDetalhe').modal('show');
  }

  
}
