import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { Subject } from 'rxjs';

declare var $ : any;

declare const jQuery: any;

@Injectable({
    providedIn: 'root'
  })
  export class ModalService{
      modalChange: Subject<any> = new Subject<any>();

    constructor(){

    }
    show(status,msg){
      this.modalChange.next({status: status,msg: msg});
      $('#ModalMsg').modal('show');
    }

}
