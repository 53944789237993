import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SessaoService } from 'src/app/services/sessao.service';

declare var $ : any;

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  @Output() pagechange = new EventEmitter<number>();

  constructor(private sessao: SessaoService) { }

  ngOnInit() {
  }

  abreLogin(){
    if(this.sessao.logado==1)
    return false;
    
    this.pagechange.emit(1);
  }

  sidebarToggle(){
    $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide');
      };
  }

}
