import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { SessaoService } from 'src/app/services/sessao.service';

declare var $: any;

declare const jQuery: any;

@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss']
})
export class TabelaComponent implements OnInit {

  logado = this.sessao.logado;
  @Input() jogos: any = [];
  @Input() isdesktop: boolean;

  @Output()editaId  = new EventEmitter();
  @Output()detalhaId  = new EventEmitter();
  @Output()abreModal  = new EventEmitter();
  iddetalhe = 0;

  @Output()editaImgErr  = new EventEmitter();
  idimg=0

  constructor(private http: HttpService, private modal: ModalService, private chRef: ChangeDetectorRef, private sessao: SessaoService) { }

  ngOnInit() {
    // this.buscar();
    // $("#tabelaJogos").DataTable({
    //   "autoWidth": false,
    //     destroy: true,
    //     responsive:false,
    //     searching:false,
    //     pageLength: 30,
    //     lengthChange : false,
    //     "paging":   false,
    //     "ordering": false,
    //     "info":     false
    // });
  }

  editaImgErrada(id){
    this.idimg = id;
    this.editaImgErr.emit(this.idimg);
  }

  editar(id){
    this.iddetalhe = id;
    this.editaId.emit(this.iddetalhe);
  }

  detalhar(id) {
    this.iddetalhe = id;
    this.detalhaId.emit(this.iddetalhe);

  }


  modalAbrir() {
    this.abreModal.emit();
  }

  buscar() {
    // console.log(URL+'/jogador/add/');
    // this.http.requisicao(URLHTTP+'/jogador/get/','').then((result)=>{
    //   console.log(result);
    //   this.jogadores=result;
    //   this.chRef.detectChanges()
    //   $("#tabela").DataTable({
    //     "autoWidth": false,
    //       destroy: true,
    //       responsive:false,
    //       searching:false,
    //       pageLength: 30,
    //       lengthChange : false,
    //       "paging":   false,
    //       "ordering": false,
    //       "info":     false
    //   });
    // })


  }

  modalCadastro() {
    $('#ModalJogador').modal('show');
  }

  cadastrar() {
    // console.log(URL+'/jogador/add/');
    // this.http.requisicao(URLHTTP+'/jogador/add/', this.jogador).then((result)=>{
    //   $('#ModalJogo').modal('hide');
      // this.jogador.nome='';
      // this.modal.show(200,"Cadastrado com sucesso")
      // this.buscar();
    // })
  }


}
