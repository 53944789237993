import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SessaoService } from 'src/app/services/sessao.service';
  
declare var $ : any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Output() pagechange = new EventEmitter<number>();

  @Input() page:number

  logado=0;

  constructor(private sessao: SessaoService) { }

  ngOnInit() {
    this.logado=this.sessao.logado;
    
      
    
  }

  setMenu(num){
    console.log('veui')
    this.pagechange.emit(num);
  }


  sidebarToggle(){
    $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide');
      };
  }
}
