import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { SessaoService } from 'src/app/services/sessao.service';

import {  FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { Utils } from '../services/utils';
import { Paths } from '../services/paths';

declare var $: any;

declare const jQuery: any;

@Component({
  selector: 'app-jogador',
  templateUrl: './jogador.component.html',
  styleUrls: ['./jogador.component.scss']
})
export class JogadorComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({ url: Paths.imgadd, itemAlias: 'foto' });

  logado = 0;

  jogadores: any = [];
  jogador = {id:0,nome: '', tipo: false, img:''};


  tabela;
  imageChangedEvent: any;
  imgcropped: any;

  teste() {
    console.log(this.jogador);
  }

  constructor(private sessao: SessaoService, private http: HttpService, private modal: ModalService, private chRef: ChangeDetectorRef) { }

  ngOnInit() {

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    

    this.buscar();
    this.logado = this.sessao.logado;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.uploader.clearQueue();
    this.uploader.cancelAll();
    $('#ModalJogador').on('hidden.bs.modal', function (e) {
      $('#modalCropped').modal('show');
      //clear this function so it doesn't show up if they exit the window again
      $('#ModalJogador').off();
  });
    $('#ModalJogador').modal('hide');
  }

  confirmaCropped() {

    console.log(this.imgcropped);
    this.uploader.addToQueue([this.imgcropped], { autoUpload: true });

    


    $('#modalCropped').on('hidden.bs.modal', function (e) {
      $('#ModalJogador').modal('show');
      //clear this function so it doesn't show up if they exit the window again
      $('#modalCropped').off();
  });      
    $('#modalCropped').modal('hide');
    
  }

  imageCropped(event) {
    this.imgcropped = event.file;
  }

  buscar() {
    if (this.tabela !== undefined) {
      this.tabela.clear();
      this.tabela.destroy();
    }
    // console.log(URL+'/jogador/add/');
    this.http.requisicao(Paths.jogadorget, '').then((result) => {
      console.log(result);
      this.jogadores = result;


      this.chRef.detectChanges();
      this.tabela = $('#tabela').DataTable({
        autoWidth: false,
          destroy: true,
          responsive: true,
          searching: false,
          pageLength: 30,
          lengthChange : false,
          paging:   false,
          ordering: false,
          info:     false
      });
    });


  }

  modalCadastro() {
    this.imageChangedEvent = undefined;
    this.uploader.clearQueue();
    this.uploader.cancelAll();
    this.jogador={id:0,nome: '', tipo: false, img:''};
    $('#ModalJogador').modal('show');
  }

  cadastrar() {

    if (this.uploader.getNotUploadedItems().length > 0) {
      console.log(this.uploader.queue)
      this.uploader.uploadAll();
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
        const resposta = JSON.parse(response);
        console.log(resposta);
        this.inserirjogador(resposta.img);
      };
    } else {
      this.inserirjogador();
    }


    // console.log(URL+'/jogador/add/');
    
  }

  inserirjogador(imgsrc= '') {
    if (imgsrc === '' || imgsrc === undefined) {
      this.jogador.img = '';
    } else {
      this.jogador.img = imgsrc;
    }

    this.http.requisicao(Paths.jogadoradd, this.jogador).then((result) => {
      $('#ModalJogador').modal('hide');
      this.jogador.nome = '';
      this.jogador.tipo = false ;
      this.jogador.img = '' ;
      this.modal.show(200, 'Cadastrado com sucesso');
      this.buscar();
    });


  }

  editar(id){
    this.imageChangedEvent = undefined;
    this.uploader.clearQueue();
    this.uploader.cancelAll();
    this.jogadores.forEach(element => {
      if(element.id==id){
        this.jogador=Utils.jsonCopy(element);
        this.jogador.tipo=element.tipo==1? false : true
        
      }
    });
    

    $('#ModalJogador').modal('show');
  }


  update(){
    if (this.uploader.getNotUploadedItems().length > 0) {
      console.log(this.uploader.queue)
      this.uploader.uploadAll();
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
        const resposta = JSON.parse(response);
        console.log(resposta);
        this.atualizar(resposta.img);
      };
    } else {
      this.atualizar();
    }

    
  }

  atualizar(imgsrc=''){
    if (imgsrc === '' || imgsrc === undefined) {
      // this.jogador.img = '';
      delete this.jogador['img']
    } else {
      this.jogador.img = imgsrc;
    }

    console.log(this.jogador)

    this.http.requisicao(Paths.jogadoratualizar, this.jogador).then((result) => {
      $('#ModalJogador').modal('hide');
      this.jogador.nome = '';
      this.jogador.tipo = false ;
      this.jogador.img = '' ;
      this.modal.show(200, 'Cadastrado com sucesso');
      this.buscar();
    });
  }

  inativar(id){

    this.http.requisicao(Paths.jogadorinativar, {id}).then((result) => {
      $('#ModalJogador').modal('hide');
      this.modal.show(200, 'Inativado com sucesso');
      this.buscar();
    });
  }


}
