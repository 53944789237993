import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { SessaoService } from 'src/app/services/sessao.service';
import { ImageService } from '../services/image.service';
import { Paths } from '../services/paths';

declare var $ : any;

declare const jQuery: any;

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {


  ranking
  geral
  ranks=[]
  rankselecionado
  
  constructor(private imgService: ImageService, private sessao: SessaoService,private http:HttpService, private modal:ModalService, private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.http.requisicao(Paths.jogoranking,'').then((result)=>{
      this.setaranking(result.geral.ranking,0);
      this.setaranking(result.ranking.ranking,1);
      this.ranks=result.ranks
      this.rankselecionado=result.ranks[0].id
    });
  }

  setaranking(tabela, identificador){

    let ranking=tabela

    for(let cont=0;cont < ranking.length;cont++){
      if(ranking[cont].img!=undefined && ranking[cont].img!=''){
        
        const req = {img: ranking[cont].img};

        this.http.requisicaoGetFile(Paths.imgget, req).then(
          (response) => {
              
            const reader = this.imgService.createImageFromBlob(response);
              reader.addEventListener('load', () => {
                ranking[cont].img = this.imgService.imageToShow;
             });
          })

      }
    }

    if(identificador==0){
      this.geral=ranking
    }

    if(identificador==1){
      this.ranking=ranking
    }

    
  }

  mudarank(){
    this.http.requisicao(Paths.jogorbuscarank,{idrank:this.rankselecionado}).then((result)=>{
      this.setaranking(result,1)
    })
  }

  

}
