
import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from './utils';


@Pipe({name: 'positivepipe'})
export class PositivePipe implements PipeTransform {
  transform(value: number): any {
	if(value==0){
		return '';
	}

	let valor = Math.abs(value) 
	return valor;

  }
}
