import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from './utils';


@Pipe({name: 'currencypipe'})
export class CurrencyPipe implements PipeTransform {
  transform(value: number, cifrao?: boolean): any {
    let valor=value;
    if (!valor){
      valor=0;
    }
    let cifra = (cifrao==null) ? '' : (cifrao==true) ? 'R$ ': ''
    return cifra + Utils.currency(valor).toString()

  }
}
