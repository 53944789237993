import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { URLHTTP } from 'src/app/services/URL';
import { ModalService } from 'src/app/services/modal.service';
import { SessaoService } from 'src/app/services/sessao.service';
import { FileUploader } from 'ng2-file-upload';
import { Utils } from '../services/utils';
import { ImageService } from '../services/image.service';
import { Paths } from '../services/paths';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var $ : any;

declare const jQuery: any;

@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.scss']
})
export class FinanceiroComponent implements OnInit {

  isDesktopDevice = this.deviceService.isDesktop();

  logado=0;

  editando=true;

  totalcaixa:0;

  financeiros:any=[]
  financeiro={id:0, img:"", jogo:{jogadores:[]}, idjogo:0,data:"", descricao:"", valor:0, tipo:'S'};

  public uploader: FileUploader = new FileUploader({ url: Paths.imgadd, itemAlias: 'foto' });


  tabela;
  imgcropped: any;
  imageChangedEvent
  imgurl: any;

  constructor(private deviceService:DeviceDetectorService, 
    private imgService: ImageService,private sessao: SessaoService,private http:HttpService, 
    private modal:ModalService, private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.buscar();
    this.logado=this.sessao.logado;

    $("#valorfin").inputmask('currency',{"autoUnmask": true,
            radixPoint:",",
            groupSeparator: ".",
            allowMinus: false,
            prefix: 'R$ ',            
            digits: 2,
            digitsOptional: true,
            rightAlign: true,
            unmaskAsNumber: true
    });

    $('#datajogo').datepicker({
      format: 'dd/mm/yyyy'
    });

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.uploader.clearQueue();
    this.uploader.cancelAll();
    $('#ModalJogador').on('hidden.bs.modal', function (e) {
      $('#modalCropped').modal('show');
      //clear this function so it doesn't show up if they exit the window again
      $('#ModalJogador').off();
  });
    $('#ModalJogador').modal('hide');
  }

  confirmaCropped() {

    console.log(this.imgcropped);
    this.uploader.addToQueue([this.imgcropped], { autoUpload: true });

    


    $('#modalCropped').on('hidden.bs.modal', function (e) {
      $('#ModalJogador').modal('show');
      //clear this function so it doesn't show up if they exit the window again
      $('#modalCropped').off();
  });
    $('#modalCropped').modal('hide');
    
  }

  imageCropped(event) {
    this.imgcropped = event.file;
  }

  buscar(){
    if(this.tabela!=undefined){
      this.tabela.clear()
      this.tabela.destroy()
    }
    // console.log(URL+'/jogador/add/');
    this.http.requisicao(Paths.financeiroget,'').then((result)=>{
      console.log(result);
      this.financeiros=result.financeiros;
      this.totalcaixa=result.totalcaixa;


      this.chRef.detectChanges()
      this.tabela=$("#tabela").DataTable({
        "autoWidth": false,
          destroy: true,
          responsive:true,
          searching:false,
          pageLength: 15,
          lengthChange : false,
          "paging":   true,
          "ordering": false,
          "info":     false,
          columnDefs: [
            { responsivePriority:4 , targets: 0 },
            { responsivePriority:3 , targets: 1 },
            { responsivePriority:0 , targets: 2 },
            { responsivePriority:2 , targets: 3 },
            { responsivePriority:1 , targets: 4 }
          ]
      });
    })


  }

  modalCadastro(){

    this.editando=true;

    this.imgcropped = undefined;
      this.uploader.clearQueue();
      this.uploader.cancelAll();

    $('#datajogo').datepicker('setDate',new Date())
    $('#valorfin').val('');
    this.financeiro={id:0, img:"", jogo:{jogadores:[]}, idjogo:0,data:Utils.date(new Date()), descricao:"", valor:0, tipo:'S'};
    $('#ModalJogador').modal('show');
  }

  cadastrar(){

    if(this.uploader.getNotUploadedItems().length>0){
      console.log('aqui')
      this.uploader.uploadAll()
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
        console.log(response)
        let resposta=JSON.parse(response)
        console.log(resposta)
        this.inserirFinanceiro(resposta.img)
      };
    }else{
      this.inserirFinanceiro()
    }

    
  }


  inserirFinanceiro(imgsrc=''){
    if(imgsrc=='' || imgsrc==undefined){
      this.financeiro.img=''
    }else{
      this.financeiro.img=imgsrc
    }


    // console.log(URL+'/jogador/add/');
    this.financeiro.data=$('#datajogo').val();
    this.financeiro.valor=$('#valorfin').val();
    // console.log(this.financeiro)
    this.http.requisicao(Paths.financeiroadd, this.financeiro).then((result)=>{
      $('#ModalJogador').modal('hide');
      this.financeiro={id:0, jogo:{jogadores:[]}, idjogo:0,data:"", descricao:"", img:"", valor:0, tipo:'S'};
      this.modal.show(200,"Cadastrado com sucesso")
      this.buscar();
    })
  }

  editar(id){


    this.editando=true;
    this.imgcropped = undefined;
      this.uploader.clearQueue();
      this.uploader.cancelAll();

    this.financeiros.forEach(element => {
      if (element.id==id){
        this.financeiro=Utils.jsonCopy(element)
      }
    });
    $('#datajogo').datepicker('setDate',this.financeiro.data)
    $('#valorfin').val(Utils.currencyToString(this.financeiro.valor));
    $('#ModalJogador').modal('show');
  }

  update(){
    if(this.uploader.getNotUploadedItems().length>0){
      console.log('aqui')
      this.uploader.uploadAll()
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
        console.log(response)
        let resposta=JSON.parse(response)
        console.log(resposta)
        this.atualizar(resposta.img)
      };
    }else{
      this.atualizar();
    }
    
  }

  atualizar(img=''){

    if (img === '' || img === undefined) {
      // this.jogo.img = '';
      delete this.financeiro['img']
    } else {
      this.financeiro.img = img;
    }

    delete this.financeiro['caixa']


    this.financeiro.data=$('#datajogo').val();
    this.financeiro.valor=$('#valorfin').val();
    // console.log(this.financeiro)
    this.http.requisicao(Paths.financeiroatualizar, this.financeiro).then((result)=>{
      $('#ModalJogador').modal('hide');
      this.financeiro={id:0, jogo:{jogadores:[]}, idjogo:0,data:"", descricao:"", img:"", valor:0, tipo:'S'};
      this.imgcropped = undefined;
      this.uploader.clearQueue();
      this.uploader.cancelAll();
      this.buscar();
    })
  }

  detalhar(id) {
    
    this.editando=false;
    this.financeiro={id:0, img:"", jogo:{jogadores:[]}, idjogo:0,data:"", descricao:"", valor:0, tipo:'S'};
    this.financeiros.forEach(element => {
      if (element.id === id) {
        this.financeiro = element;
        
        // tslint:disable-next-line:forin
        // for (const cont in element.jogadores) {
        //   const idj = element.jogadores[cont].idjogador;
        //   const eli = element.jogadores[cont].eliminadopor;
        //   const reb = element.jogadores[cont].rebuy;
        //   const hr = element.jogadores[cont].hora;
        //   const hrentr=element.jogadores[cont].horaentrada
        //   const primeiraelim=element.jogadores[cont].primeiraeliminacao
        //   this.jogadoresJogo.push({jogador: idj, eliminou: eli, rebuy: reb, hora:hr, horaentrada:hrentr, primeiraeliminacao:primeiraelim});
        // }
        // this.numJogador = this.jogadoresJogo.length;
      }
    });

    if(this.financeiro.idjogo>0){
      let jogadores=this.financeiro.jogo.jogadores;
      this.financeiro.jogo.jogadores=[];

      jogadores.forEach((element) => {
        let pos=-1
        this.financeiro.jogo.jogadores.forEach((element2,index) => {

            if(element2.idjogador==element.idjogador)
            pos=index;
          });
        if(pos>-1){
          this.financeiro.jogo.jogadores[pos].rebuy+=1;
        }else{
          element['rebuy']=0;
          this.financeiro.jogo.jogadores.push(element);
        }
      });
    }
    // this.changeJogador();

    
    // this.mask()
    // this.setaHoraEliminacao()
    $('#datajogo').datepicker('setDate',this.financeiro.data)
    $('#valorfin').val(Utils.currencyToString(this.financeiro.valor));
    $('#ModalJogador').modal('show');

  }

  inativar(id){

    this.http.requisicao(Paths.financeiroinativar, {id}).then((result)=>{
      $('#ModalJogador').modal('hide');
      this.financeiro={id:0, img:"", jogo:{jogadores:[]}, idjogo:0,data:"", descricao:"", valor:0, tipo:'S'};
      this.imgcropped = undefined;
      this.uploader.clearQueue();
      this.uploader.cancelAll();
      this.buscar();
    })

  }

  showimg(img){
    this.http.requisicaoGetFile(Paths.imgget, {img}).then(
      (response) => {
        const reader = this.imgService.createImageFromBlob(response);
        reader.addEventListener('load', () => {
           this.imgurl = this.imgService.imageToShow;
           
           $('#ModalJogador').modal('hide');
           $('#modalImagem').modal('show');

           
        });
      }
    );
  }


}
