
import { URLHTTP } from './URL';

export class Paths {

  constructor(){}


    //logica para o link ser dinamico
    public static baseurl= window.location.origin=='http://localhost:4200' ?  URLHTTP.baseurllocal : URLHTTP.baseurl

    public static readonly imgadd= Paths.baseurl+'/jogo/img/';
    // public static readonly imgget= Paths.baseurl+'/jogo/getimg/';
    public static readonly imgget= URLHTTP.baseurl+'/jogo/getimg/';
    public static readonly imgupdate= Paths.baseurl+'/jogo/updateimg/';

    public static readonly jogoget= Paths.baseurl+'/jogo/get/';
    public static readonly jogoadd= Paths.baseurl+'/jogo/add/';
    public static readonly jogoatualizar= Paths.baseurl+'/jogo/atualizar/';
    public static readonly jogoinativar= Paths.baseurl+'/jogo/inativar/';
    public static readonly jogogetedit= Paths.baseurl+'/jogo/getEdit/';

    
    public static readonly jogadorget= Paths.baseurl+'/jogador/get/';
    public static readonly jogadoradd= Paths.baseurl+'/jogador/add/';
    public static readonly jogadoratualizar= Paths.baseurl+'/jogador/atualizar/';
    public static readonly jogadorinutilizar= Paths.baseurl+'/jogador/inutilizar/';
    public static readonly jogadorinativar= Paths.baseurl+'/jogador/inativar/';


    public static readonly financeiroget= Paths.baseurl+'/financeiro/get/';
    public static readonly financeiroadd= Paths.baseurl+'/financeiro/add/';
    public static readonly financeiroatualizar= Paths.baseurl+'/financeiro/atualizar/';
    public static readonly financeiroinativar= Paths.baseurl+'/financeiro/inativar/';

    public static readonly logar= Paths.baseurl+'/login/logar/';
    
    public static readonly rankget= Paths.baseurl+'/ranking/get/';
    public static readonly rankadd= Paths.baseurl+'/ranking/add/';

    public static readonly jogoranking= Paths.baseurl+'/jogo/ranking/';
    public static readonly jogorbuscarank= Paths.baseurl+'/jogo/buscarank/';

    public static readonly estatisticas= Paths.baseurl+'/jogo/estatisticas/';
    public static readonly estatisticasbusca= Paths.baseurl+'/jogo/estatisticasbusca/';
    
    
    
    // public static readonly login= Paths.baseurl+URLS.login;
    // public static readonly acessar= Paths.baseurl+URLS.acessar;

    // public static readonly rankingvendedorget=  Paths.baseurl+URLS.rankingvendedorget;
    // public static readonly rankingvendedorcliente =  Paths.baseurl+URLS.rankingvendedorcliente;
    // public static readonly rankingvendedormarca =  Paths.baseurl+URLS.rankingvendedormarca;
    // public static readonly rankingvendedorsubgrupo =  Paths.baseurl+URLS.rankingvendedorsubgrupo;
    // public static readonly rankingvendedorgrupo =  Paths.baseurl+URLS.rankingvendedorgrupo;
    // public static readonly rankingvendedoritem =  Paths.baseurl+URLS.rankingvendedoritem;
    // public static readonly rankingvendedorpdf =  Paths.baseurl+URLS.rankingvendedorpdf;


    

}
